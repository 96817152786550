
import React, { useEffect, useState, useRef } from 'react';
import '../../src/css/chatCss.css'
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios'
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'
import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2'
import Accordion from 'react-bootstrap/Accordion';
import { BsStopCircle } from 'react-icons/bs'
import sendIcon from '../assets/send.webp'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import lamejs from 'lamejs';

import env from 'react-dotenv'
import socketIO from 'socket.io-client';



function TypingEffect({ text }) {
  return <div>{text}</div>;
}



const ChatUIDemo = (botID) => {




  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [chatbotMsg, setChatbotMsg] = useState('');
  const [suggestedPrompt, setSPrompt] = useState([])
  const [spromptHide, setSpromptHide] = useState(false)
  const [inputValueHide, setInputValueHide] = useState(false);
  const [plan, setPlan] = useState('')
  const [mark, setMark] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadingI, setLoadingI] = useState(false);
  const [audioSub, setAudioSub] = useState(false)
  const [uniqueCon, setUniqueCon] = useState(1)
  const [now, setNow] = useState('')
  const [chkk, setChkk] = useState([])
  const [consecFail, setConsecFail] = useState(0)
  const [consecFailMsg, setConsecFailMsg] = useState([])
  const [ansE, setAnsE] = useState('')
  const [queE, setQueE] = useState('')
  const [startSocket, setStartSocket] = useState(1)
  const [chatUiDe, setChatUiDe] = useState('None')
  const [errorDetected, setErrorDetected] = useState(false)
  const [rdn, setRdn] = useState(false)

  const BACKEND = process.env.REACT_APP_BACKEND
  const BACKENDWS = process.env.REACT_APP_BACKENDWS

  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState('');
  const [roomTog, setRoomTog] = useState(false);
  const [combinedData, setCombibedData] = useState([])


  useEffect(() => {
    try {
      const newSocket = socketIO.connect(BACKENDWS
        , {
          transports: ["websocket"],
          enabledTransports: ["websocket"],
        },
        { secure: true },
        // ,{transports: ['wss'], enabledTransports: ['wss'],}
      );
      // console.log("----------------------------Socket connect front",BACKEND)
      setSocket(newSocket);
      newSocket.on("connect", () => {
        // console.log("-----------------Socket connected on the front end");
      });
      newSocket.on("error", (error) => {
        // console.error("--------------Socket error on the front end:", error);
      });
      return () => {
        if (newSocket.readyState === 1) {  /** Remove if statement if error ,-------------------------- */
          newSocket.disconnect();
          // console.log("Socket DIS--connect front------return one--")
        }
      };
    } catch (err) {
      console.log(err, "======try catch")
    }
  }, [inputValue]);



  const joinRoom = (roomName) => {
    if (socket) {
      try {
        socket.emit('join', { room: roomName });
        setRoom(roomName);
      } catch (err) {
        console.log("ERR on join room frontend code == ", err)
      }

    }
  };

  const leaveRoom = () => {
    if (socket) {
      socket.emit('leave', { room });
      setRoom('');
    }
  };

  const test = () => {
    if (socket) {
      socket.emit('test');
    }
  };
  const testRoom = (roomName) => {
    if (socket) {
      socket.emit('testRoom', { room: roomName });
    }
  };


  const sendMessage = (message) => {
    if (socket) {
      socket.emit('message', { room, msg: message });
    } else {
      // console.log("no room joined")
    }
  };

  useEffect(() => {
    // console.log("Socket changeeeeeeeeeeeeeee ssssssssssssss")
    socket?.on('welcome_message', (data) => {
      // console.log('Received welcome message:', data.message);
    });
    socket?.on('test', (data) => {
      // console.log('Received test message:', data.message);
    });
    socket?.on('testRoom', (data) => {
      // console.log('Received test Room message:', data.message);
    });

  }, [socket])

  useEffect(() => {
    socket?.on('message-chat', data => {
      setChkk(prevChkk => [...prevChkk, data.message])
      console.log(data.message)
    });
///////  poi


  }, [socket])

  // const [startEvent,setStartEvent] = useState(false)
  ///////////////////////////////////////new
  // const handleStartEvent = () => {
  //   console.log('startEvent')
    
  //   const eventSource = new EventSource(`${BACKEND}register/${botID.botID}`);
  //   console.log("177")
  //   eventSource.onmessage = (event) => {
  //     console.log("eventsiurce ",event.data)
  //     setChkk(prevChkk => [...prevChkk, event.data])
  //   };

  //   return () => {
  //     eventSource.close();
  //   }
  // }


  const handleInputChange = async (e) => {
    test()
    testRoom("Roonmane")
    await setInputValue(e.target.value)
  };

  const handleSubmitP = (x) => {

    if (x.trim() === '') {
      return;
    }

    setSpromptHide(true)
    joinRoom(botID.botID)
    const newMessage = {
      id: messages.length + 1,
      text: x,
      sender: 'me',
    };
    let inputValue = x
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setLoading(true);
    scrollToBottom()
    // handleStartEvent()
    console.log("241-----------")
    
    axios.post(`${BACKEND}api/msg`, { inputValue, botID, uniqueCon, chatUiDe }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
      .then(res => {
        if (res.data === 'SubE') { setLoading(false); setUniqueCon(0); setChatbotMsg("Your services in the plan have expired. Kindly upgrade") }
        else if (res.data == 'noid') { setLoading(false); setUniqueCon(0); setChatbotMsg("Sorry, This Bot has been deleted") }
        else if (res.data[0] == 'Some Error Occured !!!!') { setLoading(false); console.log("-------111111-----", res.data); setUniqueCon(0); setChatbotMsg("Some Error Occured !!!!"); setConsecFailMsg(prev => [...prev, res.data[1]]); setConsecFail(consecFail + 1) }
        else if (res.data == 'RDNF') { setLoading(false); setChatbotMsg("Relevant Data Not Found."); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) } // RDN
        else if (res.data[1] == 'RDN') { setRdn(true); setLoading(false); setChatbotMsg(res.data[0]); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) }
        else { setChatbotMsg(res.data[0]); setConsecFail(0); setUniqueCon(0); setLoading(false) }
      })
      .catch(err => { setLoading(false); console.log(err); setConsecFail(consecFail + 1); setConsecFailMsg(prev => [...prev, err]); setChatbotMsg("Sorry, Some Error has Occured !!!! ") })
    scrollToBottom()
//  setStartEvent(false)
  }


  useEffect(() => {
    if (uniqueCon === 1) {
      setNow(new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString())
    }
  }, [uniqueCon])

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setInputValueHide(true)
    joinRoom(botID.botID)
    setSpromptHide(true)
    if (inputValue.trim() === '') {
      setInputValueHide(false)
      return;
     
    }
    if (/^\s+$/.test(inputValue)) {
      // Handle the case where the input consists only of spaces
      toast.error("Input cannot consist only of spaces");
      setInputValueHide(false)
      return;
    }
    if (/\s\s+/.test(inputValue)) {
      // Handle the case where there are consecutive spaces
      toast.error("Input cannot contain 2 consecutive spaces");
      setInputValueHide(false)

      return;
    }


    const newMessage = {
      id: messages.length + 1,
      text: inputValue,
      sender: 'me',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setLoading(true);
    scrollToBottom()
    // handleStartEvent()
    axios.post(`${BACKEND}api/msg`, { inputValue, botID, uniqueCon, chatUiDe }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
      .then(res => {
        if (res.data === 'SubE') {  setInputValueHide(false);setLoading(false); setChatbotMsg("Your services in the plan have expired. Kindly upgrade"); setUniqueCon(0) }
        else if (res.data == 'noid') {  setInputValueHide(false);setLoading(false); setChatbotMsg("Sorry, This Bot has been deleted"); setUniqueCon(0) }
        else if (res.data[0] == 'Some Error Occured !!!!') { setInputValueHide(false); setLoading(false); setChatbotMsg("Some Error Occured !!!!"); setConsecFail(consecFail + 1); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data[1]]) }
        else if (res.data == 'RDNF') { setInputValueHide(false); setLoading(false); setChatbotMsg("Relevant Data Not Found."); setUniqueCon(0); setConsecFailMsg(prev => [...prev, "Relevant Data Not Found"]); setConsecFail(consecFail + 1) }
        else if (res.data[1] == 'RDN') { setInputValueHide(false); setRdn(true); setLoading(false); setChatbotMsg(res.data[0]); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) }
        else { setInputValueHide(false); setChatbotMsg(res.data[0]); setConsecFail(0); setUniqueCon(0); setLoading(false); }
      }).catch(err => { setInputValueHide(false); setLoading(false); setConsecFail(consecFail + 1); console.log(err); setChatbotMsg("Sorry, Some Error has Occured !!!! "); setConsecFailMsg(prev => [...prev, err]) })
    scrollToBottom()

  };

  useEffect(() => {
    if (consecFail >= 1 && messages[messages.length - 1]['sender'] == '') {
      const failmsg = messages.filter(message => message.text !== '').slice(-2)
      const consecFailMsgF = consecFailMsg.slice(-1)
      setConsecFail(0);
      axios.post(`${BACKEND}api/consecFailure`, { botID, failmsg, consecFailMsgF }, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
        .then(res => { setConsecFailMsg([]); }).catch(err => console.log(err))
      // .then()
    }
  }, [messages])

  useEffect(() => {
    const filteredArray = messages.filter(item => item.text !== '');
    let errorDet = false
    const processedArray = filteredArray.map(({ id, ...x }) => {
      if (x.sender !== 'me') {
        console.log("********X====", x)
        if (x.text === 'Some Error Occured !!!!' || x.text === 'Relevant Data Not Found.' || x.text === 'Sorry, Some Error has Occured !!!! ' || x.text === "Some Error Occured !!!!" || x.text === "Sorry, Some Error has Occured !!!! " || x.text === "Your services in the plan have expired. Kindly upgrade" || x.text === "Sorry, This Bot has been deleted" || rdn === true) {
          console.log("Error detected")
          setErrorDetected(true)
          errorDet = true
        }
      }
      return x;
    });
    console.log("errorDetected still", errorDetected, errorDet)
    axios.post(`${BACKEND}api/history`, { botID, processedArray, now, uniqueCon, errorDetected: errorDet }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { console.log("chatuidemo"); setErrorDetected(false); setRdn(false) }).catch(err => { console.log(err); setErrorDetected(false); setRdn(false) })
  }, [chatbotMsg])

  const [apiload, setApiload] = useState(false)

  useEffect(() => {
    setApiload(true)
    axios.get(`${BACKEND}api/fontdata/${botID.botID}`).then(res => { setFontData(res.data); setSPrompt(res.data.sPrompt); setChatbotMsg(res.data.initialMsg); setPlan(res.data.plan); setApiload(false) }).catch(err => { console.log(err); setApiload(false) })
  }, [])

  useEffect(() => {
    setChkk([])
    const newMessage = {
      id: messages.length + 1,
      text: chatbotMsg,
      sender: '',
    };
    if (chatbotMsg === '' && chatbotMsg === null) {
      // console.log("W")
    } else {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      // if (speechTog === true) {
      //     const speechSynthesis = window.speechSynthesis;
      //     const utterance = new SpeechSynthesisUtterance(chatbotMsg);
      //     speechSynthesis.speak(utterance);
      // }

      if (speechTog === true) {
        const speechSynthesis = window.speechSynthesis;
        const maxChunkLength = 100;
        let textToSpeak = chatbotMsg;

        while (textToSpeak.length > maxChunkLength) {
          let lastSpaceIndex = textToSpeak.lastIndexOf(' ', maxChunkLength);
          if (lastSpaceIndex === -1) {
            lastSpaceIndex = maxChunkLength;
          }

          const chunk = textToSpeak.substr(0, lastSpaceIndex);
          const utterance = new SpeechSynthesisUtterance(chunk);
          speechSynthesis.speak(utterance);

          textToSpeak = textToSpeak.substr(lastSpaceIndex + 1);
        }

        if (textToSpeak) {
          const utterance = new SpeechSynthesisUtterance(textToSpeak);
          speechSynthesis.speak(utterance);
        }
      }
      // console.log("speech tog  is set to ", speechTog)

    }
    setInputValue('');
    setChatbotMsg('')
    // console.log("lllllllllllll")
    leaveRoom()
    scrollToBottom()

  }, [chatbotMsg])

  const [speechTog, setSpeechTog] = useState(false)

  useEffect(() => {
    const checkSpeech = setInterval(() => {
      if (speechTog === false) {
        // If speechTog is false, cancel the speech
        speechSynthesis.pause();
        speechSynthesis.cancel();
        // console.log("cancel")
        clearInterval(checkSpeech); // Stop the loop
      }
    }, 100);
  }, [speechTog])

  useEffect(() => {
    if (plan === 'year-enterprise' || plan === 'month-enterprise') {
      setMark(false)
    } else {
      setMark(true)
    }
    // console.log("mark = ", mark, " and plan ", plan)
  }, [plan])


  const [fontData, setFontData] = useState({
    font: 'arial',
    userFontColor: '#0070DA',
    userFontTextColor: '#FFFFFF',
    cpuFontColor: '#3D4648',
    cpuFontTextColor: '#FFFFFF',
    backgroundColor: '#242439',
    fontSize: '12px',
  });

  const handleFontChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fontSize') {
      // Prevent font size from being negative
      const fontSize = parseInt(value, 10);
      if (fontSize >= 0 && fontSize <= 32) {
        setFontData((prevData) => ({ ...prevData, [name]: fontSize }));
      }
    } else {
      setFontData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFontSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    axios.put(`${BACKEND}api/fontdata/${botID.botID}`, { fontData }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'Yes') { toast.success('Font Changes were Successful'); setLoading(false); } else { toast.success('Some error occured'); setLoading(false); } }).catch((err) => { console.log("error manage bots ", err); toast.error('API request failed!'); setLoading(false); })
  };

  const messageStyleSend = {
    backgroundColor: fontData.userFontColor,
    fontSize: fontData.fontSize,
    color: fontData.userFontTextColor,
    fontFamily: fontData.font


  };
  const messageStyleRec = {
    backgroundColor: fontData.cpuFontColor,
    fontSize: fontData.fontSize,
    color: fontData.cpuFontTextColor,
    fontFamily: fontData.font

  };

  const fontOptions = [
    'Arial',
    'Times New Roman',
    'Helvetica',
    'Courier New',
    'Verdana',
  ];

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);


  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  }
  const mimeType = "audio/webm";
  const startRecording = async () => {
    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };
  const stopRecording = () => {
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });


      setAudio(audioBlob);
      setAudioChunks([]);
    };
  };

  useEffect(() => {
    if (audio !== null) {
      const formData = new FormData();
      formData.append('audio', audio, 'recording.webm');
      for (let pair of formData.entries()) {
        // console.log(pair[0] + ', ' + pair[1]);
      }
      scrollToBottom()
      axios.post(`${BACKEND}api/audio`, formData, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }).then(res => { setInputValue(res.data); setAudioSub(true); setAudio(null) }).catch(err => console.log(err))
    }
  }, [audio])

  useEffect(() => {
    if (audioSub === true) {
      handleSubmit()
      setInputValue('')
    }
    setAudioSub(false)
  }, [audioSub])

  const handleToggleSpeech = () => {
    setSpeechTog((prevTog) => !prevTog);
    // console.log("sppppppppppppech ", speechTog)
  };

  const chatContainerRef = useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      // console.log(chatContainerRef.current.scrollHeight, "chatContainerRef.current.scrollHeight")
    }, 10);
  };

  const handleEmbedQuestion = (e) => {
    e.preventDefault()
    if (queE == '' || ansE == '') {
      toast.error('Fill both fields')
    } else {

      setLoading(true);
      axios.post(`${BACKEND}api/embedQuestion/${botID.botID}`, { queE, ansE }, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }).then(res => { 
        if (res.data === 'success') { toast.success('Questions embedded into the Knowledge Base'); setLoading(false); setAnsE(''); setQueE('');setShowNewPopup(false) }
        else if(res.data.already ){toast.error("Question already exists in Database"); setLoading(false); setAnsE(''); setQueE('')}
        else { toast.error("Some Error Occured"); setLoading(false); setAnsE(''); setQueE('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoading(false); setAnsE(''); setQueE('') })

    }
  }

  const handleIssue = (e) => {
    e.preventDefault()
    if (issue == '' || detailIssue == '') {
      toast.error('Fill both fields')
    } else {
      setLoadingI(true);
      axios.post(`${BACKEND}api/issue/${botID.botID}`, { issue, detailIssue }, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }).then(res => { if (res.data === 'success') { toast.success('Feedback / Issue submitted'); setLoadingI(false); setIssue(''); setDetailIssue('') } else { toast.error("Some Error Occured"); setLoadingI(false); setIssue(''); setDetailIssue('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoadingI(false); setIssue(''); setDetailIssue('') })
    }

  }

  const [issue, setIssue] = useState('')
  const [detailIssue, setDetailIssue] = useState('')
  const [embedDB, setEmbedDB] = useState([])
  const [embeddedQA, setEmbeddedQA] = useState([])
  const [RDNF, setRDNF] = useState([])

  const [embeddedQASaved, setEmbeddedQASaved] = useState([])

  const [isAccordionOpen3, setIsAccordionOpen3] = useState(false);
  const [answersRDNF, setAnswersRDNF] = useState({});

  const toggleAccordion3 = () => {
    setIsAccordionOpen3(!isAccordionOpen3);
  };

  const handleAnswerChange = (index, value) => {
    setAnswersRDNF(prevAnswers => ({
      ...prevAnswers,
      [index]: value,
    }));
    console.log("RDNF SET ANSWER", answersRDNF)
  };



  useEffect(() => {
    axios.get(`${BACKEND}api/updatebot/${botID.botID}`, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { setRDNF(res.data.RDNF); console.log(res.data.RDNF); setEmbeddedQA(res.data.embeddedQA); setEmbeddedQASaved(res.data.embeddedQAsaved) })
  }, [])


  const [buttonClicked, setButtonClicked] = useState({});


  const handleEmbedQuestionARDNF = (e, qa, an) => {
    e.preventDefault()

    if (!buttonClicked[qa.question]) {
      setButtonClicked((prevState) => ({
        ...prevState,
        [qa.question]: true,
      }));
    }

    const queE = qa
    const ansE = an

    console.log(qa, an)

    setLoading(true);
    axios.post(`${BACKEND}api/embedQuestion/${botID.botID}`, { queE, ansE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Questions embedded into the Knowledge Base'); setLoading(false); setAnsE(''); setQueE('');setShowPopup(false) } else { toast.error("Some Error Occured"); setLoading(false); setAnsE(''); setQueE('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoading(false); setAnsE(''); setQueE('') })
  }

  const handleEmbedSavedRemoveRDNF = (e, qa) => {
    e.preventDefault()

    if (!buttonClicked[qa.question]) {
      setButtonClicked((prevState) => ({
        ...prevState,
        [qa.question]: true,
      }));
    }

    const queE = qa
    console.log(qa, "--608")




    setLoading(true);
    axios.post(`${BACKEND}api/embedQuestionSavedRemoveRDNF/${botID.botID}`, { queE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Removed'); setLoading(false); setAnsE(''); setQueE('') } else { toast.error("Some Error Occured"); setLoading(false); setAnsE(''); setQueE('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoading(false); setAnsE(''); setQueE('') })
  }

  const handleEmbedQuestionA = (e, qa) => {
    e.preventDefault()

    if (!buttonClicked[qa.question]) {
      setButtonClicked((prevState) => ({
        ...prevState,
        [qa.question]: true,
      }));
    }

    const queE = qa.question
    const ansE = qa.answer

    console.log(qa)

    setLoading(true);
    axios.post(`${BACKEND}api/embedQuestion/${botID.botID}`, { queE, ansE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Questions embedded into the Knowledge Base'); setLoading(false); setAnsE(''); setQueE('') } else { toast.error("Some Error Occured"); setLoading(false); setAnsE(''); setQueE('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoading(false); setAnsE(''); setQueE('') })
  }

  const handleEmbedSavedRemove = (e, qa) => {
    e.preventDefault()

    if (!buttonClicked[qa.question]) {
      setButtonClicked((prevState) => ({
        ...prevState,
        [qa.question]: true,
      }));
    }

    const queE = qa.question
    const ansE = qa.answer

console.log(queE,ansE,"----690")

    setLoading(true);
    axios.post(`${BACKEND}api/embedQuestionSavedRemove/${botID.botID}`, { queE, ansE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Removed'); setLoading(false); setAnsE(''); setQueE('') } else { toast.error("Some Error Occured"); setLoading(false); setAnsE(''); setQueE('') } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); setLoading(false); setAnsE(''); setQueE('') })
  }

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const [isAccordionOpen2, setIsAccordionOpen2] = useState(false);

  const toggleAccordion2 = () => {
    setIsAccordionOpen2(!isAccordionOpen2);
  };

  useEffect(() => {

    setCombibedData([
      ...RDNF?.map((item, index) => ({
        type: 'RDNF',
        question: item,
        answer: answersRDNF[index] || '',
        index,
      })) || [],
      ...embeddedQA?.map((item, index) => ({
        type: 'Embedded',
        question: item.question,
        answer: item.answer,
        index,
      })) || [],
    ]);

  }, [RDNF, embeddedQA])

  const [showPopup, setShowPopup] = useState(false);
  const [showNewPopup, setShowNewPopup] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newAnswer, setNewAnswer] = useState('');

  const handleAddButtonClick = (question) => {
    setSelectedQuestion(question);
    setShowPopup(true);
  };

  const handlePopupSubmit = () => {
    handleEmbedQuestionARDNF(selectedQuestion, newAnswer);
    setShowPopup(false);
    setNewAnswer('');
  };

  const [loadingMetaDel, setLoadingMetaDel] = useState(false);

  const handleRemoveMetadata = (data) => {
    // e.preventDefault()
    setLoadingMetaDel(true)
    axios.post(`${BACKEND}api/deletemetadata/${botID.botID}`, { data }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data[0] == 'Error') { console.log(res.data[1]); toast.error('Some Error Occured!!!'); setLoadingMetaDel(false) } else if (res.data == 'ok') { toast.success("Data Removed"); setTimeout(() => { window.location.reload(true) }, 1000); setLoadingMetaDel(false) } else { console.log(res.data); toast.error("Some Error Occured"); setLoadingMetaDel(false) } }).catch(err => { toast.error('Some Error Occured'); console.log(err); setLoadingMetaDel(false) })

  }

  const [removingIndex, setRemovingIndex] = useState(null);

  const handleRemoveClick = (e, index, question, item) => {
    setRemovingIndex(index);
    handleRemoveMetadata(e.target.value);
    handleEmbedSavedRemoveRDNF(e, question);
    handleEmbedSavedRemove(e, item);
    console.log('etargetval rm meta',e.target.value)
    console.log('que remove rdnf',question)
    console.log('item remove',item)
  };




  return (
    <div className='row d-flex justify-content-around flex-wrap px-3'>

      <div className='form-group d-flex justify-content-center mt-4 ' >
        {apiload ? (
          <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />

        ) : (
          ''
        )}
      </div>
      {/* <div style={{ backgroundColor: '#212529' }} className=' rounded-4 my-5 col-lg-5 col-10 d-flex justify-content-center '>
                <form className='d-flex row justify-content-around mt-3 ' style={{width:''}} onSubmit={handleFontSubmit}>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label className='fs-4 d-flex justify-content-center container text-center mb-1' style={{ height: '100%', color: '#FFFFFF' }} >Update how your Chatbot looks</label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <Form.Label>Font:</Form.Label>
                        <DropdownButton
                            title={fontData.font || 'Select a font'}
                            variant="secondary"
                            drop='down-centered'
                            id="dropdown-font"
                            onSelect={(eventKey) => handleFontChange({ target: { name: 'font', value: eventKey } })}
                        >
                            {fontOptions.map((fontOption) => (
                                <Dropdown.Item key={fontOption} eventKey={fontOption}>
                                    {fontOption}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            Client Font Color:
                            <input
                                type="color"
                                name="userFontColor"
                                value={fontData.userFontColor}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            Client Text Color:
                            <input
                                type="color"
                                name="userFontTextColor"
                                value={fontData.userFontTextColor}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            CPU Font Color:
                            <input
                                type="color"
                                name="cpuFontColor"
                                value={fontData.cpuFontColor}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            CPU Text Color:
                            <input
                                type="color"
                                name="cpuFontTextColor"
                                value={fontData.cpuFontTextColor}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            Background Color:
                            <input
                                type="color"
                                name="backgroundColor"
                                value={fontData.backgroundColor}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div style={{ color: '#FFFFFF' }} className='col-10 col-sm-8 fs-5 rounded-3 text-center  py-2  my-3' >
                        <label>
                            Font Size:
                            <input
                                type="number"
                                name="fontSize"
                                style={{ width: '60px' }}
                                value={fontData.fontSize}
                                onChange={handleFontChange}
                                required
                            />
                        </label>
                    </div>
                    <div className='col-8 col-sm-9 fs-5 rounded-3 text-center  py-2  my-3' >
                        <button className='btn btn-outline-warning px-5' type="submit">Submit</button>
                    </div>
                </form>
            </div> */}
   <div style={{ backgroundColor: '#212529', borderBottom: "1px solid grey" }} className='my-1 col-12 d-flex justify-content-center flex-wrap'>
  <div style={{ color: '#FFFFFF' }} className='col-12 fs-5 rounded-3 text-center py-0 mb-3'>
    <label className='fs-4 d-flex justify-content-center container text-center mb-1' style={{ height: '100%', color: '#FFFFFF' }}>
      Update how your Chatbot looks
    </label>
  </div>
  <Form className="p-4 rounded shadow-lg" onSubmit={handleFontSubmit} style={{ backgroundColor: '#2c2f33' }}>
  <div className="row">
    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>Chatbot Font Type:</Form.Label>
      <div className="d-flex justify-content-start" style={{}}>
        <DropdownButton
          title={fontData.font || 'Select a font'}
          variant="secondary"
          drop="down-centered"
          id="dropdown-font"
          style={{}}
          onSelect={(eventKey) => handleFontChange({ target: { name: 'font', value: eventKey } })}
        >
          {fontOptions.map((fontOption) => (
            <Dropdown.Item key={fontOption} eventKey={fontOption}>
              {fontOption}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>Chatbot Font Size:</Form.Label>
      <Form.Control
        type="number"
        name="fontSize"
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
        value={fontData.fontSize}
        onChange={handleFontChange}
        required
      />
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>User Chat Text Background Color:</Form.Label>
      <Form.Control
        type="color"
        name="userFontColor"
        value={fontData.userFontColor}
        onChange={handleFontChange}
        required
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
      />
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>User Chat Text Color:</Form.Label>
      <Form.Control
        type="color"
        name="userFontTextColor"
        value={fontData.userFontTextColor}
        onChange={handleFontChange}
        required
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
      />
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>Chatbot Response Text Background Color:</Form.Label>
      <Form.Control
        type="color"
        name="cpuFontColor"
        value={fontData.cpuFontColor}
        onChange={handleFontChange}
        required
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
      />
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>Chatbot Response Text Color:</Form.Label>
      <Form.Control
        type="color"
        name="cpuFontTextColor"
        value={fontData.cpuFontTextColor}
        onChange={handleFontChange}
        required
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
      />
    </Form.Group>

    <Form.Group className="col-12 mb-4 d-flex justify-content-between">
      <Form.Label className="text-white fs-5 rounded-3 py-2 px-3 mb-2 d-inline-block" style={{  width: 'fit-content' }}>Chatbot Background Color:</Form.Label>
      <Form.Control
        type="color"
        name="backgroundColor"
        value={fontData.backgroundColor}
        onChange={handleFontChange}
        required
        style={{width:'100px',height:'41px',minWidth:'100px', margin: '0' }}
      />
    </Form.Group>
  </div>

  <Form.Group className="col-12 text-start">
    <button className="btn btn-outline-warning btn-lg px-5" type="submit">Submit</button>
  </Form.Group>
</Form>


</div>
      {/* ##################################### Chat UI DEMO */}

     
      <div className='d-flex justify-content-center mt-2 mb-0 pb-4  col-12' style={{ borderBottom: "1px solid grey" }}>

        <div className='d-flex  ' style={{ position: 'relative', height: '600px', minWidth: '280px', maxWidth: '540px', width: '100%', marginTop: '0px' }}>
          {
            mark ?
              <h5 className=' mx-3 rounded-3 px-3' style={{ backgroundColor: fontData.cpuFontColor,color:fontData.cpuFontTextColor, position: 'absolute', opacity: '45%', left: '20px', bottom: '50px' }}>Powered by Zema</h5> : <p></p>
          }
          <div className="chat-container px-0" ref={chatContainerRef} style={{ backgroundColor: fontData.backgroundColor, height: '600px', paddingBottom: '100px', maxWidth: '540px', minWidth: '240px', width: '100%', borderWidth: '0px' }}>
            <div className="chat-messages " >
              {messages.map((message) => (
                message.text === '' || null || undefined ? "" : <>
                  <div
                    key={message.id}
                    className={`message ${message.sender === 'me' ? 'sent' : 'received'}`}
                    style={message.sender === 'me' ? messageStyleSend : messageStyleRec}
                  >
                    {/* <div id="text-container" className="message-content typing">{message.text}</div> */}
                    <TypingEffect id="text-container" className="message-content " text={message.text} />
                    {/* <p  id="text-container" className="message-content ">{message.text}</p> */}

                  </div>
                </>
              ))}
              {
                chkk.length == 0 ? '' : <p style={messageStyleRec} className="message  received" >{chkk}</p>
              }

              {/* <label className='fs-4 d-flex justify-content-center container text-center mb-1' style={{ height: '100%', color: '#FFFFFF' }} >{chkk}</label>           */}

              {loading ? (
                <ThreeDots type="Oval" position="top-center" color={fontData.cpuFontColor} height={50} width={50} />

              ) : (
                ''
              )}
            </div>
            {/* #######################3 suggested prompts */}
            <div style={{ position: 'absolute', left: '20px', bottom: '50px', width: '75%' }}>
              {suggestedPrompt === undefined || spromptHide === true ? '' : suggestedPrompt.map(x => (
                <div className='received message' onClick={() => { handleSubmitP(x) }} value={x} style={{ backgroundColor: fontData.cpuFontColor, fontSize: fontData.fontSize, color: fontData.cpuFontTextColor, fontFamily: fontData.font, width: '75%' }}  >{x}</div>
              ))}
            </div>
          </div>
          <form className="chat-input mt-5" style={{ minHeight: '50px', height: '50px', maxWidth: '540px', minWidth: '240px', width: '100%', bottom: '-2px', position: 'absolute', backgroundColor: fontData.backgroundColor, borderColor: 'black', borderWidth: '2px' }} onSubmit={(e) => handleSubmit(e)}>
            <input
              type="text"
              style={{ minWidth: '50%' }}
              value={inputValueHide ? '' :inputValue}
              onChange={handleInputChange}
              placeholder="Type a message..."
            />
            {
              plan === 'year-enterprise' || plan === 'year-standard' || plan === 'month-enterprise' || plan === 'month-standard' ?
                <>
                  <div className="me-2">
                    {
                      speechTog === false ?
                        <button onClick={handleToggleSpeech} type="button">
                          <HiSpeakerXMark />
                        </button> :
                        <button onClick={handleToggleSpeech} type="button">
                          <HiSpeakerWave />
                        </button>
                    }
                  </div>

                  {/* <div className="audio-controls me-2">
                    {!permission ? (
                      <button onClick={getMicrophonePermission} type="button">
                        <FaMicrophoneSlash />
                      </button>
                    ) : null}
                    {permission && recordingStatus === "inactive" ? (
                      <button onClick={startRecording} type="button">
                        <FaMicrophone />
                      </button>
                    ) : null}
                    {recordingStatus === "recording" ? (
                      <button onClick={stopRecording} type="button">
                        <BsStopCircle />
                      </button>
                    ) : null}
                  </div> */}
                </> : ''

            }
            <button type="submit" ><img src={sendIcon} alt='Send' style={{ height: '22px', width: '25px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} /></button>
            {/* <button type="submit" ><img src="https://icons.veryicon.com/png/o/internet--web/iview-3-x-icons/ios-send.png" style={{ height:'25px', width:'40px', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}/></button> */}
          </form>
          {/* {audio ? (
  <div className="audio-container">
     <audio src={audio} controls></audio>
     <a download href={audio}>
        Download Recording
     </a>
   </div>
) : null} */}
        </div>
      </div>

     

      <div className='mx-0 px-0' style={{ paddingBottom: '10px', borderBottom: "1px solid grey" }}>
  <div className='container px-0 my-4'>
    <div className='row rounded-4 col-12 mx-auto mt-4 mb-3 d-flex' style={{ border: "1px solid grey" }}>
      <Card style={{ backgroundColor: combinedData.length !== 0 ? '#242439' : '#171725' }} className='d-flex rounded-4'>
        <h3 className='mt-4 text-center' style={{ color: 'white' }}>Questions Chatbot was not able to answer</h3>
        <Card.Body className="d-flex flex-column p-1">
          <div style={{ overflowX: 'auto' }}>
            <Table bordered hover variant="dark" className='rounded-4 my-1'>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Add Answer</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {combinedData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.question}</td>
                    <td>
                      {item.type === 'RDNF' ? (
                        item.answer || <span className='text-muted'>No answer</span>
                      ) : (
                        item.answer
                      )}
                    </td>
                    <td>
                      {item.type === 'RDNF' ? (
                        <Button
                          variant='outline-primary'
                          className='mx-1'
                          onClick={() => handleAddButtonClick(item.question)}
                        >
                          Add
                        </Button>
                      ) : null}
                    </td>
                    <td>
                      <Button
                        variant='outline-danger'
                        className='mx-1'
                        value={item.question}
                        onClick={(e) => handleRemoveClick(e, index, item.question, item)}
                      >
                        {loadingMetaDel && removingIndex === index ? (
                          <ThreeDots type="Oval" position="top-center" color="#fff" height={20} width={20} />
                        ) : (
                          'Remove'
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='d-flex justify-content-end mb-1'>
            <Button variant='outline-warning' onClick={() => setShowNewPopup(true)}>
              + Add Data
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  </div>

  {showPopup && (
    <div className='modal show' style={{ display: 'block' }} onClick={() => setShowPopup(false)}>
      <div className='modal-dialog' role='document' onClick={(e) => e.stopPropagation()}>
        <div className='modal-content' style={{ backgroundColor: '#171725', border: '1px solid white' }}>
          <div className='modal-header'>
            <h5 className='modal-title'>Add Answer</h5>
            <button type='button' className='btn-close' onClick={() => setShowPopup(false)}></button>
          </div>
          <div className='modal-body'>
            <p><strong>Question:</strong> {selectedQuestion}</p>
            <textarea
              className='form-control'
              type='text'
              placeholder='Enter answer'
              value={newAnswer}
              onChange={(e) => setNewAnswer(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' onClick={() => setShowPopup(false)}>Close</button>
            <button type='button' className='btn btn-primary' onClick={(e) => handleEmbedQuestionARDNF(e, selectedQuestion, newAnswer)} disabled={loading}>
              {loading ? <ThreeDots type="Oval" color="#fff" height={20} width={20} /> : 'Add and Embed'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )}

  {showNewPopup && (
    <div className='modal show' style={{ display: 'block' }} onClick={() => setShowNewPopup(false)}>
      <div className='modal-dialog' role='document' onClick={(e) => e.stopPropagation()}>
        <div className='modal-content' style={{ backgroundColor: '#171725', border: '1px solid white' }}>
          <div className='modal-header'>
            <h5 className='modal-title'>Embed specific questions</h5>
            <button type='button' className='btn-close' onClick={() => setShowNewPopup(false)}></button>
          </div>
          <div className='modal-body'>
            <div className="form-group">
              <label style={{ color: 'white' }}>Question</label>
              <textarea
                className='form-control'
                value={queE}
                onChange={(e) => setQueE(e.target.value)}
                placeholder='Question'
                disabled={loading}
              />
            </div>
            <div className="form-group">
              <label style={{ color: 'white' }}>Answer</label>
              <textarea
                className='form-control'
                value={ansE}
                onChange={(e) => setAnsE(e.target.value)}
                placeholder='Answer'
                disabled={loading}
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' onClick={() => setShowNewPopup(false)} disabled={loading}>Close</button>
            <button type='button' className='btn btn-primary' onClick={(e) => handleEmbedQuestion(e)} disabled={loading}>
              {loading ? <ThreeDots type="Oval" color="#fff" height={20} width={20} /> : 'Embed Questions'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
</div>

         {/* {
          RDNF && RDNF.length > 0 && (
            <>
             
              <Accordion alwaysOpen={false} className='col-11 my-4 mx-auto custom-accordion' style={{ marginBottom: '100px' }}
                activeKey={isAccordionOpen3 ? '0' : null}
                onSelect={toggleAccordion3}
              >
                <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #171725' }} >
                  <Accordion.Header>
                    <label className='fs-4 d-flex justify-content-center container text-center' style={{ height: '100%', color: '#FFFFFF' }}>
                      RDNF
                    </label>
                    <span style={{
                      color: '#FFE459',
                      position: 'absolute',
                      right: '25px', // Adjust this value to fine-tune the position
                      top: '50%',
                      fontSize: '34px',
                      transform: 'translateY(-50%)',
                    }}>
                      {isAccordionOpen3 ? '-' : '+'}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: 'white', backgroundColor: '#171725' }} >
                    {RDNF.map((itemSaved, index) => {
                      //   const isCommon = embeddedQA.some(item => item.question === itemSaved.question && item.answer === itemSaved.answer);
                      return (
                        <div className='my-5' key={index}>
                          <div>
                            <input
                              className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3'
                              style={{ width: '95%' }}
                              readOnly
                              placeholder='RDNF'
                              value={itemSaved}
                            />
                            <input
                              className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3'
                              style={{ width: '95%' }}
                              placeholder='RDNF Answer'
                              value={answersRDNF[index] || ''}
                              onChange={(e) => handleAnswerChange(index, e.target.value)}
                            />
                          </div>
                          <div className='d-flex flex-wrap justify-content-center'>
                            <button
                              className='btn btn-outline-warning px-5 my-1 mx-3'
                              onClick={(e) => handleEmbedQuestionARDNF(e, itemSaved, answersRDNF[index])}
                            //   disabled={buttonClicked[itemSaved.question] || isCommon}
                            >
                              Embed Question
                            </button>
                            <button className='btn btn-outline-danger px-5 my-1 mx-3'
                              onClick={(e) => handleEmbedSavedRemoveRDNF(e, itemSaved)}
                            //  disabled={buttonClicked[itemSaved.question] || isCommon}
                            >Remove Question from RDNF</button>
                          </div>
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )
        } */}



        {/* {
          embeddedQA && embeddedQA.length > 0 && (
            <>
            
              <Accordion alwaysOpen='false' className='col-11 my-2 mx-auto custom-accordion' style={{ marginBottom: '100px' }}
                activeKey={isAccordionOpen ? '0' : null}
                onSelect={toggleAccordion}
              >
                <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #171725' }} >
                  <Accordion.Header >
                    <label className='fs-4 d-flex justify-content-center container text-center' style={{ height: '100%', color: '#FFFFFF' }}>
                      Embedded Q/A
                    </label>
                    <span style={{
                      color: '#FFE459',
                      position: 'absolute',
                      right: '25px', // Adjust this value to fine-tune the position
                      top: '50%',
                      fontSize: '34px',
                      transform: 'translateY(-50%)',
                    }}>
                      {isAccordionOpen ? '-' : '+'}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: 'white', backgroundColor: '#171725' }} >
                    {embeddedQA.map((item, index) => (
                      <>
                        <div key={index} className='mt-5'>
                          <input
                            className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3'
                            style={{ width: '95%' }}
                            readOnly
                            placeholder='Embedded Q/A'
                            value={item.question}
                          />
                          <textarea
                            className='fs-4 d-flex justify-content-center container mt-1 text-center mb-5'
                            style={{ width: '95%' }}
                            readOnly
                            placeholder='Embedded Q/A'
                            value={item.answer}
                          />
                        </div>
                      </>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )
        } */}


        {/* {
          embeddedQASaved && embeddedQASaved.length > 0 && (
            <>
              <Accordion alwaysOpen='false' className='col-11 my-4 mx-auto custom-accordion' style={{ marginBottom: '100px' }}
                activeKey={isAccordionOpen2 ? '0' : null}
                onSelect={toggleAccordion2}
              >
                <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #171725' }} >
                  <Accordion.Header >
                    <label className='fs-4 d-flex justify-content-center container text-center ' style={{ height: '100%', color: '#FFFFFF' }}>
                      Embedded Q/A Saved
                    </label>
                    <span style={{
                      color: '#FFE459',
                      position: 'absolute',
                      right: '25px', // Adjust this value to fine-tune the position
                      top: '50%',
                      fontSize: '34px',
                      transform: 'translateY(-50%)',
                    }}>
                      {isAccordionOpen2 ? '-' : '+'}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: 'white', backgroundColor: '#171725' }} >
                    {embeddedQASaved.map((itemSaved, index) => {
                      const isCommon = embeddedQA.some(item => item.question === itemSaved.question && item.answer === itemSaved.answer);
                      return (
                        <div className='my-5' key={index}>
                          <div>
                            <input
                              className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3'
                              style={{ width: '95%' }}
                              readOnly
                              placeholder='Embedded Q/A'
                              value={itemSaved.question}
                            />
                            <input
                              className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3'
                              style={{ width: '95%' }}
                              readOnly
                              placeholder='Embedded Q/A'
                              value={itemSaved.answer}
                            />
                          </div>
                          <div className='d-flex flex-wrap justify-content-center'>
                            <button
                              className='btn btn-outline-warning px-5 my-1 mx-3'
                              onClick={(e) => handleEmbedQuestionA(e, itemSaved)}
                              disabled={buttonClicked[itemSaved.question] || isCommon}
                            >
                              Embed Question
                            </button>
                            <button className='btn btn-outline-danger px-5 my-1 mx-3'
                              onClick={(e) => handleEmbedSavedRemove(e, itemSaved)}
                              disabled={buttonClicked[itemSaved.question] || isCommon}
                            >Remove Question </button>
                          </div>
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )
        } */}

      <div className='d-flex justify-content-center my-2  col-12'>
        <form className=' mb-2 col-12 mx-auto ' style={{ marginTop: '10px' }}>
          <div className="form-group d-flex justify-content-center mt-1 mb-1 fs-2 fw-bold ">
            <label className='text-center' style={{ color: 'white' }} >Feedback or Issues</label>
          </div>
          <div className="form-group">
            <label style={{ color: 'white' }} >Feedback / Issue</label>
            <textarea className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3' onChange={(e) => setIssue(e.target.value)} value={issue} placeholder='Feedback / Issue' />
          </div>
          <div className="form-group">
            <label style={{ color: 'white' }} >Details</label>
            <textarea className='fs-4 d-flex justify-content-center container mt-1 text-center mb-3' value={detailIssue} onChange={(e) => setDetailIssue(e.target.value)} placeholder='Details' />
          </div>
          <div className='form-group d-flex mt-2 justify-content-center'>
            <button className='btn btn-outline-warning  mb-3 px-5  ' onClick={(e) => handleIssue(e)}>Submit</button>
          </div>
          <div className='form-group d-flex justify-content-center'>
            {loadingI ? (
              <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
            ) : (
              ''
            )}
          </div>
        </form>
      </div>

      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </div>
  )
}


export default ChatUIDemo;