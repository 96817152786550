import React, { useState, useEffect } from 'react'
import '../DashboardPage/sidenav.css'
import { FaRobot } from "react-icons/fa";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { MdSmsFailed } from "react-icons/md";
import { FaCoins } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import { ThreeDots, TailSpin } from 'react-loader-spinner';
import Cookies from 'js-cookie'
import noimg from '../../assets/dumlogo.webp'
import { ToastContainer, toast } from 'react-toastify';

const DashboardForClient = () => {

  const decoded = Cookies.get('accessToken');
  const BACKEND = process.env.REACT_APP_BACKEND

  const [data2, setData2] = useState('')
  const [dataBotId, setDataBotId] = useState('')
  const [dataArr, setDataArr] = useState([])
  const [botId, setBotId] = useState('')
  const [dataHistory, setDataHistory] = useState(0)
  const [dataHistoryLoading, setDataHistoryLoading] = useState(false)
  const [dataHistoryFail, setDataHistoryFail] = useState(0)
  const [dataHistoryFailLoading, setDataHistoryFailLoading] = useState(false)
  const [apiload, setApiload] = useState(false)
  const [apiload2, setApiload2] = useState(true)

  useEffect(() => {
    setApiload(true)
    axios.post(`${BACKEND}api/subdata`, { decoded }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }).then(res => { setData2(res.data[0]);console.log('data2',res.data[0]); setApiload(false) }).catch(err => { console.log(err); setApiload(false) })
  }, [])

  useEffect(() => {
    setApiload(true)
    axios.post(`${BACKEND}api/mybotsids`, { decoded }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
      .then(res => { setDataBotId(res.data); console.log(res.data); setApiload(false); setApiload2(false) }).catch(err => { console.log(" error from mybots", err); setApiload(false); setApiload2(false) })
  }, [])

  useEffect(() => {

    axios.post(`${BACKEND}api/mybots`, { decoded }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
      .then(res => { setDataArr(res.data); console.log('52---', res.data) })
      .catch(err => { console.log(" error from mybots", err) })
  }, [])

  useEffect(() => {
    setDataHistoryLoading(true)
    if (dataBotId.length !== 0) {

      axios.post(`${BACKEND}api/historynumberget`, { decoded, dataBotId }, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
        .then(res => { setDataHistory(res.data.total_count); console.log(res.data.total_count); setApiload(false); setDataHistoryLoading(false) }).catch(err => { console.log(" error from mybots", err); setApiload(false); setDataHistoryLoading(false) })

    } else {
      setDataHistoryLoading(false)
    }
  }, [dataBotId])

  useEffect(() => {
    setDataHistoryFailLoading(true)
    if (dataBotId.length !== 0) {

      axios.post(`${BACKEND}api/historyfailnumberget`, { decoded, dataBotId }, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
        .then(res => { setDataHistoryFail(res.data.total_count); console.log(res.data.total_count); setApiload(false); setDataHistoryFailLoading(false) }).catch(err => { console.log(" error from mybots", err); setApiload(false); setDataHistoryFailLoading(false) })

    }
  }, [dataBotId])


  function abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ["k", "m", "b", "t"];
    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round(number * decPlaces / size) / decPlaces;
        if ((number == 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
        number += abbrev[i];
        break;
      }
    }
    return number;
  }

  return (
    <div className='pb-5 responsive-side-nav-other px-0' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>
      <h1 className='pt-4 mb-4 px-3 text-white'>Dashboard</h1>
      <div className='form-group d-flex justify-content-center mt-4'>
        {apiload ? (
          <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />

        ) : (
          ''
        )}
      </div>
      <div className='d-flex justify-content-center flex-wrap pe-3 '>

        <div className=' col-lg-6 col-12 d-flex justify-content-around flex-wrap  px-0'>

          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }}>
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  <h1 class="card-title">{data2.BotsUsed}/{data2.BotsUsed + data2.NoOfBots}</h1>
                 
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">My bots</h6>
                </div>
                <div className='col-4'>
                  <FaRobot style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <Link to='/mychatbotsdash' className='text-center text-white fw-bold' style={{ textDecoration: 'none', position: 'absolute', bottom: '0px', backgroundColor: '#620B84', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  Details
                </Link>
              </div>
            </div>
          </div>

          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }} >
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  {/* <h4 class="card-title" style={{ fontSize: '23px' }}>{abbrNum(data2.tokens, 1)}/{abbrNum(data2.totalTokens, 1)}</h4> */}
                  <h4 className="card-title" style={{ fontSize: '23px' }}>
  {abbrNum(Math.max(0, data2.tokens), 1)}/{abbrNum(data2.totalTokens, 1)}
</h4>
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Tokens Left</h6>
                </div>
                <div className='col-4'>
                  <FaCoins style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <Link to='/tokenleftdash' className='text-center text-white fw-bold' style={{ textDecoration: 'none', position: 'absolute', bottom: '0px', backgroundColor: '#620B84', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  Details
                </Link>
              </div>
            </div>
          </div>



        </div>

        <div className=' col-lg-6 col-12 d-flex justify-content-around flex-wrap  px-0 '>


          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }}>
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  <h1 class="card-title">{dataHistoryLoading ? <TailSpin height="40" width="40" color="#620B84" /> : dataHistory}</h1>
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Chats</h6>
                </div>
                <div className='col-4'>
                  <HiChatBubbleLeftRight style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <Link to='/conversationdash' className='text-center text-white fw-bold' style={{ textDecoration: 'none', position: 'absolute', bottom: '0px', backgroundColor: '#620B84', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  Details
                </Link>
              </div>
            </div>
          </div>

          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }} >
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  <h1 class="card-title">{dataHistoryLoading ? <TailSpin height="40" width="40" color="#620B84" /> : dataHistoryFail}</h1>
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Failed Chats</h6>
                </div>
                <div className='col-4'>
                  <MdSmsFailed style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <Link to='/failedconversationdash' className='text-center text-white fw-bold' style={{ textDecoration: 'none', position: 'absolute', bottom: '0px', backgroundColor: '#620B84', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  Details
                </Link>
              </div>
            </div>
          </div>

        </div>

        <h3 className='fw-bolder col-12 d-flex justify-content-center container text-center mt-3 pt-5 mb-4' style={{ color: '#FFFFFF' }}>My Chatbots</h3>

        <br />


        {
          dataArr.length === 0 && apiload === false && apiload2 === false ?
            <h3 className=' text-white text-center'>No bot has been created by you yet.</h3> : ''
        }
        {
          dataArr.length === 0 && apiload === true ?
            <div className="mb-1 py-2 col-lg-12 d-flex justify-content-center">
              <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
            </div>
            : ''
        }
        <div className='d-flex justify-content-center col-12 flex-wrap mt-2 mx-5'>
          {dataArr.map(x => (

            <div className='d-flex justify-content-around col-xxl-3 col-xl-4 col-lg-4 col-sm-9 col-10 mb-2 mt-5 mb-4 mx-3' style={{ width: '300px', minWidth: '250px', }}>
              <div className="card px-1" style={{ width: '500px', minWidth: '250px', }}>
                <div className="circular-image-container" style={{ width: '100px', height: '100px' }}> {/* Adjust the width and height as needed */}
                  <img
                    className="circular-image bg-black"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain', // Or try 'contain', 'fill', 'scale-down' depending on your need
                      borderRadius: '50%' // Make the image circular
                    }}
                    src={x.botLogo || noimg}
                    alt="loading"
                  />
                </div>
                <div className="card-content px-1 " style={{ height: '100%' }}>
                  <p className='fs-4 col-12  d-flex justify-content-center container text-center'>{x.name}</p>
                </div>
                {
                  x.NoOfCharacters === 'Infinity' ?
                    '' :
                    <div className=' col-12 d-flex justify-content-center container text-center mb-3  mt-2 fs-5 text-break' style={{ color: 'black' }}>Characters Left: {x.NoOfCharacters} </div>
                }
                <div className="card-content mt-2 py-0 px-1 " style={{}}>
                  <Button value={x.id}
                    onClick={(e) => setBotId(e.target.value)}
                    className='fw-bolder px-0 mx-0 col-12 d-flex justify-content-center container text-center '
                    style={{ color: '#FFFFFF', backgroundColor: '#620B84' }}
                    variant="primary"
                  ><Link to={`/managebotsdash/${x.id}`} style={{ textDecoration: 'none', color: 'white', width: '100%' }}>Details</Link></Button>
                </div>
                <div className='fs-4 col-12 d-flex justify-content-center  text-center ' style={{}}>
                  <p className='fs-6   fw-bold ' style={{ color: 'orange' }} >

                  </p>
                </div>
              </div>
            </div>

          ))}
        </div>


        {data2.NoOfBots <= 0 ?
          <div className='  d-flex my-2 justify-content-center container text-center' >
            <button className='btn btn-primary mb-5 px-4 rounded-3 px-0 d-flex justify-content-center container text-center py-2 mb-1' style={{ color: '#FFFFFF', backgroundColor: '#620B84', maxWidth: '300px' }} variant="primary"><Link to='' aria-disabled='true' onClick={() => toast.info('You have used up all the chatbots provided in your plan !!')} style={{ textDecoration: 'none', color: 'white', width: '100%' }}>Create Chatbots</Link></button>
          </div> :
          <div className='  d-flex my-2 justify-content-center container text-center' >
            <button className='btn btn-primary mb-5 px-4 rounded-3 px-0 d-flex justify-content-center container text-center py-2 mb-1' style={{ color: '#FFFFFF', backgroundColor: '#620B84', maxWidth: '300px' }} variant="primary"><Link to='/createchatbotdash' style={{ textDecoration: 'none', color: 'white', width: '100%' }}>Create Chatbots</Link></button>
          </div>
        }


      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </div>
  )
}

export default DashboardForClient