import React, { useState, useEffect } from 'react'
import { IoReorderThree } from "react-icons/io5";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { FaListUl } from 'react-icons/fa';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/Zema_Logo_Original.webp'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie'

const VerticalNavDash = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [cross, setCross] = useState(false)

  const [sideNavChoose, setSideNavChoose] = useState('Stats')

  const handleSideNav = (e) => {
    // e.preventDefault()
    console.log(e)
    setSideNavChoose(e)
  }
  const location = useLocation(); // Get current location

  // Function to determine the background color based on the current path
  const getLinkStyle = (path) => {
    return location.pathname === path ? 'grey' : 'transparent';
  };


  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 768);
      setCross(screenWidth < 768)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const decoded = Cookies.get('accessToken');
  const BACKEND = process.env.REACT_APP_BACKEND

  const [data2, setData2] = useState('')
  const [apiload, setApiload] = useState(false)

  useEffect(() => {
    setApiload(true)
    axios.post(`${BACKEND}api/subdata`, { decoded }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }).then(res => { setData2(res.data[0]); setApiload(false); console.log(res.data[0], '--side') }).catch(err => { console.log(err); setApiload(false) })
  }, [])

  return (
    <>
      <aside style={{ position: 'fixed', top: '0px', overflowY: 'auto', minHeight: '100vh', zIndex: '2', maxWidth: '268px', width: '220px', backgroundColor: 'black' }} className={`sidebar-dark-primary elevation-4 ${!isSmallScreen ? '' : 'd-none'}`}>

        <a className="brand-link" style={{ height: '55px' }}>
        </a>
        <div className={`d-flex m-2 ${isSmallScreen == false ? '' : 'd-none'}`} style={{ color: 'white' }} onClick={() => setIsSmallScreen(true)}>
          <RxCrossCircled className={`ms-auto ${cross ? '' : 'd-none'}`} />
        </div>


        <div className="sidebar">

        <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar col-12 flex-column me-2" data-widget="treeview" role="menu" data-accordion="false">
        {/* Logo */}
        <NavLink className="navbar-brand mx-auto" style={{ height: '85px', width: '85px' }} to="/">
          <img src={logo} style={{ height: '85px', width: '85px', borderRadius: '100%' }} className='mx-2 ' alt="Logo" />
        </NavLink>

        {/* Navigation Items */}
        <li className="nav-item col-12 d-flex flex-wrap">
          <button style={{ color: 'white' }} className="nav-link col-12 btn btn-link text-start">
            <FaListUl style={{ color: 'white', display: 'none' }} className='me-2' />
          </button>
          
          {/* Dashboard Link */}
          <li className="nav-item col-12 my-2">
            <NavLink className='mx-3'>
              <Link
                to='/dashboard'
                className='fw-bolder underline-grow p-1 rounded-3'
                style={{
                  color: '#FFFFFF',
                  backgroundColor: getLinkStyle('/dashboard')
                }}
              >
                Dashboard
              </Link>
            </NavLink>
          </li>

          {/* Token Usage Link */}
          <li className="nav-item col-12 my-2">
            <NavLink className='mx-3'>
              <Link
                to='/tokenleftdash'
                className='fw-bolder underline-grow p-1 rounded-3'
                style={{
                  color: '#FFFFFF',
                  backgroundColor: getLinkStyle('/tokenleftdash')
                }}
              >
                Token Usage
              </Link>
            </NavLink>
          </li>

          {/* Chats Link */}
          <li className="nav-item col-12 my-2">
            <NavLink className='mx-3'>
              <Link
                to='/conversationdash'
                className='fw-bolder underline-grow p-1 rounded-3'
                style={{
                  color: '#FFFFFF',
                  backgroundColor: getLinkStyle('/conversationdash')
                }}
              >
                Chats
              </Link>
            </NavLink>
          </li>

          {/* Failed Chats Link */}
          <li className="nav-item col-12 my-2">
            <NavLink className='mx-3'>
              <Link
                to='/failedconversationdash'
                className='fw-bolder underline-grow p-1 rounded-3'
                style={{
                  color: '#FFFFFF',
                  backgroundColor: getLinkStyle('/failedconversationdash')
                }}
              >
                Failed Chats
              </Link>
            </NavLink>
          </li>

          {/* My Chatbots Link */}
          <li className="nav-item col-12 my-2">
            <NavLink className='mx-3'>
              <Link
                to='/mychatbotsdash'
                className='fw-bolder underline-grow p-1 rounded-3'
                style={{
                  color: '#FFFFFF',
                  backgroundColor: getLinkStyle('/mychatbotsdash')
                }}
              >
                My Chatbots
              </Link>
            </NavLink>
          </li>

          {/* Create Chatbot Link */}
          {data2.NoOfBots  <= 0 ? (
            <li className="nav-item col-12 my-2">
              <NavLink className='mx-3'>
                <Link
                  to=''
                  aria-disabled='true'
                  className='fw-bolder underline-grow p-1 rounded-3'
                  style={{
                    color: '#FFFFFF',
                    backgroundColor: 'transparent'
                  }}
                  onClick={() => toast.info('You have used up all the chatbots provided in your plan !!')}
                >
                  Create Chatbot
                </Link>
              </NavLink>
            </li>
          ) : (
            <li className="nav-item col-12 my-2">
              <NavLink className='mx-3'>
                <Link
                  to='/createchatbotdash'
                  className='fw-bolder underline-grow p-1 rounded-3'
                  style={{
                    color: '#FFFFFF',
                    backgroundColor: getLinkStyle('/createchatbotdash')
                  }}
                >
                  Create Chatbot
                </Link>
              </NavLink>
            </li>
          )}

          {/* Upgrade Subscription Tier Link */}
          <li className="nav-item col-12 mt-4">
            <NavLink className=''>
              <Link
                to='/pricing'
                className='btn btn-warning fw-bolder  p-2 rounded-3'
                style={{
                  color: 'black',
                 
                }}
              >
                Upgrade Subscription Tier
              </Link>
            </NavLink>
          </li>
        </li>
      </ul>
    </nav>
        </div>
      </aside>
      <IoReorderThree size={42} style={{ top: '10px', left: '10px', position: 'fixed', zIndex: '2', color: 'black' }} onClick={() => setIsSmallScreen(false)} />
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </>
  )
}

export default VerticalNavDash