import React, { useState} from 'react';
import '../Homepage/gradientCss.css'

// const Loader = () => (
//     <div className="loader-container">
//       <div className="loader"></div>
//     </div>
//   );

const SectionOneHome = () => {
    return (
        <div className="sectionOneGradient" style={{ height: '100%', width: '100vw' }}>
        {/* <div className="" style={{backgroundColor: '#171725', height: '100%', width: '100vw' }}> */}
        <div rel="preload" className="mx-4 divp">
            <h1 className="siz flex-wrap col-12 d-flex justify-content-center text-center" style={{ color: '#FFFFFF' }}>
                AI-Native 
                <span className="h1bubble px-4 mx-2 py-0 rounded-pill"> Knowledge </span> 
                {/* <span className=" px-4 mx-2 py-0 rounded-pill"> Knowledge </span>  */}
                Management & 
                <span className="h1bubble2 px-4 mx-2 py-0 rounded-pill"> Customer </span> 
                {/* <span className=" px-4 mx-2 py-0 rounded-pill"> Customer </span>  */}
                Engagement Solutions
            </h1>
            <p className="fw-bolder sizp col-12 d-flex justify-content-center container py-2 text-center" style={{ color: '#FFFFFF' }}>
                Optimize your business operations and customer interactions with our advanced AI solutions designed for effective company knowledge management and seamless customer engagement.
            </p>
        </div>
    </div>
    )
}

export default SectionOneHome
