import React,{useEffect} from 'react'
import '../BlogPage/gradientCss.css'

const Section1blog = () => {

  return (
    <div className='pb-0  ' style={{ backgroundColor: '#242439', height: '100%',width:'100vw' }}>
               <h1 className='fs-1 col-12 d-flex justify-content-center container text-center mb-0 pb-2 pt-4' style={{ color: '#FFFFFF' }}>Blogs</h1>
               <p className='fs-4 col-12 d-flex justify-content-center container text-center mb-0 pb-4 pt-2' style={{ color: '#FFFFFF' }}>"Unlocking Knowledge and Nurturing Creativity in Every Post"</p>         
    </div>
  )
}

export default Section1blog